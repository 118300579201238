<template>
    <base-card
      class="show-room"
    >
      <v-row
        class="fill-height no-gutters"
        align="center"
      >
        <v-col
          offset="1" 
          cols="11"
        >
          <v-container>
            <v-row>
              <v-col
                cols="11"
              >
                <v-row>
                  <medalla
                    v-for="medalla in this.getAllMedals()"
                    :key="medalla.id"
                    :value="medalla"
                    :cols_number="2"
                    contain
                  />
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
      <v-row
        class="all-bottles"
      >
        <v-col
          cols-sm="8"
          
        >
          <v-img
            :src="require('@/assets/botellas/all_bottles.jpg')"
            height="450px"
            width="100%"
            margin-top="50px"
            contain
          />
        </v-col>
      </v-row>
    </base-card>
  </template>
  
  <script>
    import { mapState } from 'vuex'
  
    export default {
      name: 'MedalDisplayRoom',
      components: {
        Medalla: () => import('@/components/base/Medalla'),
      },
      computed: {
        ...mapState(['medallas']),
      },
      methods: {
        getAllMedals () {
            return this.medallas
        },
        get2022Row () {
          return this.medallas.slice(0, 2)
        }, 
        get2021Row () {
          return this.medallas.slice(2, 4)
        },
        get2020Row () {
          return this.medallas.slice(4, 8)
        },
        get2019Row () {
          return this.medallas.slice(8, 10)
        },
        get2018Row () {
          return this.medallas.slice(10, 12)
        },
      },
    }
  </script>
  
  <style scoped>
    .show-room {
      background-color: #fff;
      padding-top: 10px;
    }
    .all-bottles {
      padding-top: 10px;
    }
    .medalla-margin {
      margin-left: 25px;
    }
  </style>
  